<template>
  <div class="container">
    <div class="row justify-content-center align-items-center mt-5 mb-5" style="min-height: calc(100vh - 6rem)">
      <div class="col-12 col-sm" style="max-width: 400px" v-if="init">
        <img class="img-fluid mb-3" :src="item.url_image">
        <h5> {{ item.name }}</h5>
        <div class="mb-3"> ฿{{ item.price | number }}</div>

        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-auto">
            <number-input size="small" controls center v-model="qty" :min="1" :max="getMaxPurchase"
                          inline></number-input>

          </div>
          <div class="col-auto"> มีสินค้าทั้งหมด {{ item.quantity }} ชิ้น</div>
        </div>

        <div v-if="!disable">
          <div class="mb-3 w-100 btn btn-primary" @click="purchase">
            ซื้อสินค้า
          </div>
        </div>

        <div v-else>
          <div class="mb-3 w-100 btn btn-secondary">
            {{ message }}
          </div>
        </div>

        <hr>

        <div>รายละเอียด</div>
        <div v-html="item.detail"></div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';
import {
  GET_ITEM_INFO, SET_RDP_USER_ID, CHECK_PERMISSION
} from "@/store/actions.type";
import {mapState} from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

export default {
  name: 'Home',
  computed: {
    ...mapState({
      item: state => state.payment.itemInfo,
    }),
    getMaxPurchase() {
      try {
        return this.item.quantity <= this.item.max_purchase_quantity ? this.item.quantity : this.item.max_purchase_quantity;
      } catch (e) {
        return 1;
      }

    }
  },
  data() {
    return {
      disable: false,
      init: false,
      message: "สินค้าหมด",
      qty: 1
    }
  },
  async created() {
    if (this.$route.query.item) {
      // set pbid and euid
      if (this.$route.query.pbid || this.$route.query.euid || this.$route.query.origin_name) {
        await store.dispatch(SET_RDP_USER_ID, {
          rpId: this.$route.query.pbid,
          euid: this.$route.query.euid,
          originName: this.$route.query.origin_name
        });
      }

      // get item info
      let data = await store.dispatch(GET_ITEM_INFO, this.$route.query.item);
      if (!data) {
        return await this.$router.push('/message')
      }

      // check time
      let startTime = dayjs(this.item.start_datetime_utc).local().tz('Asia/Bangkok').unix();
      let currentTime = dayjs().local().tz('Asia/Bangkok').unix();

      if (currentTime < startTime) {
        this.disable = true;
        this.message = 'เริ่มในเร็วๆนี้';
      }

      // check permission
      if (this.item.user_permission_required === "yes") {
        await this.checkPermission();
      }

      this.init = true;

      // check item enable
      if (this.item.enable === 0) {
        this.disable = true;
      }

      // check quantity
      if (this.item.quantity === 0) {
        this.disable = true;
      }

    } else {
      await this.$router.push('/message')
    }
  },
  methods: {
    async checkPermission() {
      // check pbid
      if (!this.$route.query.pbid) {
        this.disable = true;
        this.message = 'บัญชีนี้ไม่อยู่ในเงื่อนไขที่ร่วมรายการ';
        return;
      }

      // check quantity
      if (this.item.quantity === 0) {
        this.disable = true;
        this.message = 'สินค้าหมด';
        return;
      }

      let allowed = await store.dispatch(CHECK_PERMISSION, {
        id: this.item.unique_id,
        pbid: this.$route.query.pbid,
      });

      // check permissions
      if (allowed.status !== 'yes') {
        this.disable = true;
        this.message = 'บัญชีนี้ไม่อยู่ในเงื่อนไขที่ร่วมรายการ';
      }
    },
    async purchase() {
      this.$store.state.payment.qty = this.qty;
      await this.$router.push('/contact');
    }
  }
}
</script>
